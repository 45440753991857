body, html {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #FFFFFF;
  position: absolute;
  height: 100%;
  width: 100%;
  padding: 0;
  margin: 0;
  overflow: hidden;
}
code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
main > div, main, #root {
  width: 100%;
  height: 100%;
  text-align: center;
}
button {
  display: inline-block;
}
nav {
  display: flex;
  justify-content: space-between;
}
nav h5 {
  padding: 0 20px;
}
#logo {
  width: 60%;
  margin: 0 auto;
}
.progressBar {
  margin: 5% auto;
  width: 70%;
  height: 10%;
  border: 6px solid white;
  border-radius: 6px;
  background-color: gray;
  box-shadow: inset 0 0 15px black;
  position: relative;
  transform: skew(-15deg, -15deg) rotate(15deg);
  overflow: hidden;
}

.progressBackgroundImage {
  transform: skew(15deg, 15deg) rotate(-15deg);
  position: absolute;
  left:-20vw;
  height: 100%;
  opacity: 0.3;
  filter: grayscale(100%);
}

.progressBarFill {
  position: absolute;
  left: 0;
  height: 100%;
  overflow: hidden;
}

.progressFillBackgroundImage {
  transform: skew(15deg, 15deg) rotate(-15deg);
  position: absolute;
  left:-20vw;
  height: 100%;
}

.skyline {
  position: absolute;
  bottom: 0;
  top: 0;
  left:0;
  right: 0;
  z-index: -10;
  width: 100%;
}

.headerValue {
  padding-left: 10px;
  font-weight: normal;
}
p {
  font-size: 26px;
  font-family: 'Knewave', cursive;
}

/* Shine */
.progressBarFill:after {
	content:'';
  top:0;
	transform:translateX(100%);
  opacity: 80%;
	width:580%;
	height:100%;
	position: absolute;
	z-index:1;
	animation: slide 1s infinite;

  /*
  CSS Gradient - complete browser support from http://www.colorzilla.com/gradient-editor/
  */
  background: -moz-linear-gradient(left, rgba(255,255,255,0) 0%, rgba(255,255,255,0.8) 50%, rgba(128,186,232,0) 99%, rgba(125,185,232,0) 100%); /* FF3.6+ */
	background: -webkit-gradient(linear, left top, right top, color-stop(0%,rgba(255,255,255,0)), color-stop(50%,rgba(255,255,255,0.8)), color-stop(99%,rgba(128,186,232,0)), color-stop(100%,rgba(125,185,232,0))); /* Chrome,Safari4+ */
	background: -webkit-linear-gradient(left, rgba(255,255,255,0) 0%,rgba(255,255,255,0.8) 50%,rgba(128,186,232,0) 99%,rgba(125,185,232,0) 100%); /* Chrome10+,Safari5.1+ */
	background: -o-linear-gradient(left, rgba(255,255,255,0) 0%,rgba(255,255,255,0.8) 50%,rgba(128,186,232,0) 99%,rgba(125,185,232,0) 100%); /* Opera 11.10+ */
	background: -ms-linear-gradient(left, rgba(255,255,255,0) 0%,rgba(255,255,255,0.8) 50%,rgba(128,186,232,0) 99%,rgba(125,185,232,0) 100%); /* IE10+ */
	background: linear-gradient(to right, rgba(255,255,255,0) 0%,rgba(255,255,255,0.8) 50%,rgba(128,186,232,0) 99%,rgba(125,185,232,0) 100%); /* W3C */
	filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#00ffffff', endColorstr='#007db9e8',GradientType=1 ); /* IE6-9 */
}

/* animation */

@keyframes slide {
	0% {transform:translateX(100%);}
	100% {transform:translateX(-100%);}
}
